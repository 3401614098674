
export const useSettings = () => {
  
    return {
      darkMode :false,
      toggleDarkMode: false,
      soundEnabled:true,
      toggleSound :false,
    };
  };
  